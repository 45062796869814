exports.components = {
  "component---src-pages-about-md": () => import("./../../../src/pages/about.md" /* webpackChunkName: "component---src-pages-about-md" */),
  "component---src-pages-glossary-md": () => import("./../../../src/pages/glossary.md" /* webpackChunkName: "component---src-pages-glossary-md" */),
  "component---src-pages-settings-md": () => import("./../../../src/pages/settings.md" /* webpackChunkName: "component---src-pages-settings-md" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-thanks-for-subscribing-js": () => import("./../../../src/pages/thanksForSubscribing.js" /* webpackChunkName: "component---src-pages-thanks-for-subscribing-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-editors-notes-js": () => import("./../../../src/templates/editors-notes.js" /* webpackChunkName: "component---src-templates-editors-notes-js" */),
  "component---src-templates-feature-js": () => import("./../../../src/templates/feature.js" /* webpackChunkName: "component---src-templates-feature-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-newsletter-js": () => import("./../../../src/templates/newsletter.js" /* webpackChunkName: "component---src-templates-newsletter-js" */),
  "component---src-templates-newsletter-landing-page-js": () => import("./../../../src/templates/newsletterLandingPage.js" /* webpackChunkName: "component---src-templates-newsletter-landing-page-js" */),
  "component---src-templates-podcast-page-js": () => import("./../../../src/templates/podcast-page.js" /* webpackChunkName: "component---src-templates-podcast-page-js" */),
  "component---src-templates-season-js": () => import("./../../../src/templates/season.js" /* webpackChunkName: "component---src-templates-season-js" */)
}

